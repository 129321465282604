<template>
	<img :src="logoPath" :class="$style.img" alt="n8n.io" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useRootStore, useUIStore } from '@/stores';

export default defineComponent({
	computed: {
		...mapStores(useRootStore, useUIStore),
		basePath(): string {
			return this.rootStore.baseUrl;
		},
		logoPath(): string {
			return this.basePath + this.uiStore.logo;
		},
	},
});
</script>

<style lang="scss" module>
.img {
	height: 32px;
}
</style>
